export const ENVIRONMENT_API_BASE_URL =
  process.env.NEXT_PUBLIC_ENVIRONMENT_API_BASE_URL ||
  'http://localhost:3000/env-api';

export const TACT_API_BASE_URL =
  process.env.NEXT_PUBLIC_TACT_API_BASE_URL || 'http://localhost:3000/tact-api';

export const PORTAL_URL = process.env.NEXT_PUBLIC_PORTAL_URL || '';

export const BASE_URL =
  process.env.NEXT_PUBLIC_BASE_URL || 'http://localhost:3000';

export const TACT_API_KEY = process.env.NEXT_PUBLIC_TACT_API_KEY || '';

export const MAX_DESTINATION_COUNT = 15;
export const MAX_SWITCHER_COUNT = 40;

export const NAVBAR_HEIGHT = 69.75;
export const FOOTER_HEIGHT = 33;
export const SIDEBAR_SEARCH_DEBOUNCE_MS = 1000;
export const CREATE_DESTINATION_URL_CHECK_DEBOUNCE_MS = 750;

// Auth0 Constants
export const AUTH0_DOMAIN = process.env.NEXT_PUBLIC_AUTH0_DOMAIN || '';
export const AUTH0_CLIENT_ID = process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID || '';
export const AUTH0_AUDIENCE = process.env.NEXT_PUBLIC_AUTH0_AUDIENCE || '';
export const AUTH0_LOCAL_TOKEN =
  process.env.NEXT_PUBLIC_AUTH0_LOCAL_TOKEN || '';

export const COOKIE_DOMAIN =
  process.env.NEXT_PUBLIC_COOKIE_DOMAIN || 'localhost';
