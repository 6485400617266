import {
  AUTH0_AUDIENCE,
  AUTH0_CLIENT_ID,
  AUTH0_DOMAIN,
  BASE_URL,
} from '@/constants';
import { generateRandomID } from '@/utils/generateRandomId';
import { Auth0DecodedHash, WebAuth } from 'auth0-js';
import Cookies from 'js-cookie';

export const AUTH0_NONCE = 'nonce';
export const AUTH0_COOKIE_TOKEN_NAME = 'appSession';
export const AUTH0_COOKIE_LOOPASS_UID_NAME = 'LoopassUID';

export const authService = () => {
  return new WebAuth({
    domain: AUTH0_DOMAIN,
    clientID: AUTH0_CLIENT_ID,
    redirectUri: `${BASE_URL}?authorized=true`,
    audience: AUTH0_AUDIENCE,
    scope: 'openid profile email',
    responseType: 'token id_token',
  });
};

const storeNonceLocally = (
  stateId: string,
  returnUrl: string | null,
  searchParams: Record<string, string> = {},
) => {
  sessionStorage.setItem(
    AUTH0_NONCE,
    JSON.stringify({
      [stateId]: {
        returnUrl: decodeURIComponent(returnUrl || ''),
        searchParams,
      },
    }),
  );
};

export const login = (
  webAuth: WebAuth,
  returnUrl: string | null,
  searchParams: Record<string, string> = {},
) => {
  const stateId = generateRandomID(10);

  storeNonceLocally(stateId, returnUrl, searchParams);
  webAuth.authorize({
    responseType: 'token id_token',
    redirectUri: `${BASE_URL}?authorized=true`,
    audience: AUTH0_AUDIENCE,
    scope: 'openid profile email',
    prompt: 'login',
    state: stateId,
  });
};

export const logout = (webAuth: WebAuth) => {
  Cookies.remove(AUTH0_COOKIE_TOKEN_NAME);
  Cookies.remove(AUTH0_COOKIE_LOOPASS_UID_NAME);

  webAuth.logout({
    clientID: AUTH0_CLIENT_ID,
    returnTo: BASE_URL,
  });
};

export const handleCheckSession = (webAuth: WebAuth): Promise<string> => {
  return new Promise((resolve, reject) => {
    webAuth.checkSession(
      {
        domain: AUTH0_DOMAIN,
        scope: 'openid profile email',
      },
      (err, authResult) => {
        if (err) {
          reject(err);
        }
        if (authResult?.accessToken) {
          resolve(authResult.accessToken);
        }
      },
    );
  });
};

export const handleParseUser = (
  webAuth: WebAuth,
): Promise<Auth0DecodedHash> => {
  return new Promise((resolve, reject) => {
    webAuth.parseHash({ hash: window?.location?.hash }, (err, authResult) => {
      if (err) {
        reject(err);
      }
      if (authResult?.accessToken) {
        resolve(authResult);
      }
    });
  });
};
